import { Utils } from '@/Utils';
import ActionToast from '@/svelte/lib/ui/toasts/ActionToast.svelte';
import { empty, windowWhen } from 'rxjs';

/**
 * @typedef {Object} HXEvent
 * @property {string} type
 * @property {string|HTMLElement} target
 * @property {function} handler
 */
const hxForms = {
  separator: '##',
  localStorageKey: 'hx-forms:' + location.pathname,
  /** @type {HXEvent[]} */
  events: [],
  clearEvents() {
    this.events = this.events
      .map((event) => {
        window.$(event.target).off(event.type, event.handler);
      })
      .filter(Boolean);
  },
  initForms() {
    this.loadFormsInitialData();
    this.setupEvents();
    this.loadCodependantFilters();
    this.disableOptionsCodependantFilters();
    this.loadAjaxFilters();
  },
  convertYearToDate(year, start = true) {
    if (start) {
      return '01/01/' + year;
    } else {
      return '31/12/' + year;
    }
  },
  setValue(el, val) {
    if (['checkbox', 'radio'].includes(el.attr('type'))) {
      el.prop('checked', true).trigger('change');
      el.parent().addClass('active');
    } else if (el.get()[0].tagName === 'SELECT') {
      const split = val.split(this.separator);
      const v = split[0];
      const t = (split[1] ?? v).trim();

      const option = el.find(`option[value="${v}"]`);
      if (!option.length) {
        const option = new Option(t, v, true, true);
        el.append(option);
      } else {
        option.attr('selected', true);
      }

      el.trigger('change');
    } else {
      el.val(val).trigger('change');
    }
  },
  updateLocalStorage(search) {
    const searchData = {
      search: search
    };

    localStorage.setItem(this.localStorageKey, JSON.stringify(searchData));
    return searchData;
  },
  loadFormsInitialData() {
    if (!window.forms?.length) {
      return;
    }

    let search = window.$.deparam(location.search.slice(1));
    const ls = JSON.parse(localStorage.getItem(this.localStorageKey)) ?? {};

    let searchData = {};
    if (Object.keys(search).length) {
      searchData = this.updateLocalStorage(search);
    } else if (Object.keys(ls).length) {
      searchData = ls;
    } else {
      return;
    }

    for (const filter of Object.entries(searchData.search)) {
      const [name, value] = filter;
      /*
            0 - type
            1 - value
             */
      if (typeof value === 'object') {
        let el = window.$(`[name="${name}"]`);
        if (!el.length) {
          el = window.$(`[name="${name}[]"]`);
        }
        if (!el.length) {
          continue;
        }
        for (const val of value) {
          this.setValue(el, val);
        }
      } else {
        let el = window.$(`[name="${name}"]`);
        if (!el.length) {
          el = window.$(`[name="${name}[]"]`);
        }
        if (!el.length) {
          continue;
        }
        this.setValue(el, value);
      }
    }
  },
  setupEvents() {
    if (window.formGroups && window.formGroups.length) {
      const resetProfileHandler = (e) => {
        e.preventDefault();
        e.currentTarget.blur();
        const form = window.$(e.target).closest('form');
        const nativeForm = form[0];

        window.$.ajax({
          url: window.route('profile.filters.index'),
          type: 'GET',
          success: (data) => {
            const toastAction = {
              label: 'Set up your profile filters',
              callback: () => {
                location.href = window.route('profile.filters.show');
              }
            };

            if (!Object.keys(data).length) {
              window.toast(ActionToast, 'error', {
                props: {
                  message: "You don't have profile filters.",
                  action: toastAction
                }
              });

              return;
            }

            form[0].reset();

            let hasFiltersForPage = false;

            data.forEach((filter) => {
              window.$(`[data-filter-name="${filter.name}"]`).each((_, el) => {
                hasFiltersForPage = true;

                if (el.type === 'radio') {
                  if (el.value === filter.data) {
                    el.checked = true;
                    window
                      .$(el)
                      .attr('checked', true)
                      .trigger('change')
                      .parent()
                      .addClass('active');
                  } else {
                    el.checked = false;
                    window.$(el).attr('checked', false).parent().removeClass('active');
                  }
                  return;
                }

                //if filter.data is string, then it's a text input or single select
                if (typeof filter.data === 'string') {
                  window.$(el).val(filter.data).trigger('change');
                  return;
                }

                filter.data.forEach((val) => {
                  const value = val;
                  let text = val;
                  if (el.dataset.ajax) {
                    text = 'Loading...';
                  }
                  window.$(el).append(new Option(text, value, true, true));
                  window.$(el).val(filter.data).trigger('change');
                });
              });
            });

            if (!hasFiltersForPage) {
              window.toast(ActionToast, 'error', {
                props: {
                  message: "You have don't have profile filters that match the current page.",
                  action: toastAction
                }
              });
            }

            Utils.auditLog('filters_reset_to_default', undefined, {
              from: location.pathname,
              filters: data
            });

            this.loadAjaxFilters();
          },
          error: function () {
            window.toast('Something went wrong. Please try again later.', 'error');
          },
          complete: function () {
            if (nativeForm.id != 'renewals' && nativeForm.id != 'renewals-calendar') {
              let datePublishedStartFilter = form.find(
                '[data-filter-name="DatePublishedStartFilter"]'
              );
              if (datePublishedStartFilter) {
                const currentYear = new Date().getFullYear();
                const currentMonth = new Date().getMonth();

                if (currentMonth === 0) {
                  // January
                  datePublishedStartFilter.datepicker('setDate', new Date(currentYear - 1, 11));
                } else {
                  datePublishedStartFilter.datepicker('setDate', new Date(currentYear, 0, 1));
                }
              }
            } else {
              let renewalFilter = form.find('[data-filter-name="RenewalYearFilter"]');
              if (renewalFilter) {
                const currentYear = new Date().getFullYear();
                renewalFilter.append(new Option(currentYear, currentYear, true, true));
                renewalFilter.append(new Option(currentYear + 1, currentYear + 1, true, true));
              }
            }
          }
        });
      };
      window.$('#resetProfile').on('click', resetProfileHandler);
      this.events.push({ handler: resetProfileHandler, target: '#resetProfile', type: 'click' });

      const resetFormHandler = function (e) {
        e.preventDefault();
        e.currentTarget.blur();
        const form = window.$(this).closest('form');
        const nativeForm = form[0];
        nativeForm.reset();

        let renewalFilter = form.find('[data-filter-name="RenewalYearFilter"]');
        if (renewalFilter) {
          const currentYear = new Date().getFullYear();
          renewalFilter.append(new Option(currentYear, currentYear, true, true));
          renewalFilter.append(new Option(currentYear + 1, currentYear + 1, true, true));
        }

        if (nativeForm.id != 'renewals' && nativeForm.id != 'renewals-calendar') {
          let datePublishedStartFilter = form.find('[data-filter-name="DatePublishedStartFilter"]');
          if (datePublishedStartFilter) {
            const currentYear = new Date().getFullYear();
            const currentMonth = new Date().getMonth();

            if (currentMonth === 0) {
              // January
              datePublishedStartFilter.datepicker('setDate', new Date(currentYear - 1, 11));
            } else {
              datePublishedStartFilter.datepicker('setDate', new Date(currentYear, 0, 1));
            }
          }
        }
      };
      window.$('#resetForm').on('click', resetFormHandler);
      this.events.push({ handler: resetFormHandler, target: '#resetForm', type: 'click' });

      const createDefaultMonitorsHandler = function (e) {
        e.preventDefault();
        e.currentTarget.blur();
        const form = window.$(this).closest('form');
        const data = hxForms.getFormData(form);

        delete data._method;
        delete data._token;

        Utils.auditLog('create_monitors_from_profile', 'User clicked on create default monitors', {
          from: location.pathname
        });

        window.$.ajax({
          url: '/api/monitor/create-defaults',
          type: 'POST',
          data: data,
          success: function (data) {
            if (data.message) {
              window.toast(data.message, 'success');
            }
          },
          error: function (data) {
            window.toast(data.responseJSON.message, 'error');
          }
        });
      };
      window.$('#createDefaultMonitors').on('click', createDefaultMonitorsHandler);
      this.events.push({
        handler: createDefaultMonitorsHandler,
        target: '#createDefaultMonitors',
        type: 'click'
      });

      const createMonitorHandler = function (e) {
        e.preventDefault();
        e.currentTarget.blur();
        const form = window.$(this).closest('form');
        const nativeForm = form[0];

        const data = hxForms.getFormData(form);

        //fix for wrongly named country field in open tenders form
        if (nativeForm.id == 'open-tenders') {
          data.authority_country = data.country;
          delete data.country;
        }

        //navigate to /monitor/create
        let params = hxForms.getFilterParams(data);

        const newUrl = location.protocol + '//' + location.host + '/monitor/create' + params;

        window.location.href = newUrl;
      };
      window.$('#createMonitor').on('click', createMonitorHandler);
      this.events.push({ handler: createMonitorHandler, target: '#createMonitor', type: 'click' });

      const renewalsSwitchHandler = function (e) {
        e.preventDefault();
        e.currentTarget.blur();
        const form = window.$(this).closest('form');
        const data = hxForms.getFormData(form);
        let params = hxForms.getFilterParams(data);

        let newUrl = '';
        if (e.currentTarget.getAttribute('data-type') == 'renewals-table') {
          newUrl = route('opportunities.renewals-calendar') + params;
        } else {
          newUrl = route('opportunities.renewals') + params;
        }
        window.location.href = newUrl;
      };
      window.$('#renewalsSwitch').on('click', renewalsSwitchHandler);
      this.events.push({
        handler: renewalsSwitchHandler,
        target: '#renewalsSwitch',
        type: 'click'
      });
    }

    const searchExamplesHandler = function () {
      let type = '';
      let example_search = [];
      let fragment = $(document.createDocumentFragment());
      $('#search').val(null).trigger('change');

      //keywords for IT
      if (window.$(this).hasClass('it-example')) {
        type = 'IT';
        example_search = [
          'IT',
          'ITC',
          'ICT',
          'web',
          'portal',
          'development',
          'software',
          'database',
          'computer',
          'computers',
          'ECM',
          'CMS',
          'DMS',
          'ML',
          'intranet',
          'internet',
          'consultancy',
          'elearning',
          'e-learning',
          'etraining',
          'e-training',
          'cyber',
          'cyber-security',
          'cybersecurity',
          'security'
        ];
      }
      //keywords for communications
      if (window.$(this).hasClass('communications-example')) {
        type = 'communications';
        example_search = [
          'communication',
          'event',
          'webinar',
          'conference',
          'media',
          'multimedia',
          'publishing',
          'journalist',
          'PR',
          'marketing',
          'visual',
          'storytelling',
          'influencer'
        ];
      }

      example_search.forEach((val) => {
        let newOption = new Option(val, val, true, true);
        fragment.append(newOption);
      });

      window.$('#search').append(fragment).trigger('change');

      Utils.auditLog('search_filter_examples_used', 'User clicked on search examples', {
        from: location.pathname,
        button_type: type
      });
    };
    window.$('.search-examples').on('click', searchExamplesHandler);
    this.events.push({
      handler: searchExamplesHandler,
      target: '.search-examples',
      type: 'click'
    });

    window.$(window.formGroups).each((_, formGroup) => {
      const form = window.$(`#${formGroup.identifier}`);

      let renewalFilter = form.find('[data-filter-name="RenewalYearFilter"]');
      if (renewalFilter && renewalFilter.find('option').length === 0) {
        const currentYear = new Date().getFullYear();
        renewalFilter.append(new Option(currentYear, currentYear, true, true));
        renewalFilter.append(new Option(currentYear + 1, currentYear + 1, true, true));
      }

      //check if url has filter params
      let search = window.$.deparam(location.search.slice(1));

      if (
        Object.keys(search).length == 0 &&
        formGroup.identifier != 'renewals' &&
        formGroup.identifier != 'renewals-calendar'
      ) {
        let datePublishedStartFilter = form.find('[data-filter-name="DatePublishedStartFilter"]');
        if (datePublishedStartFilter && datePublishedStartFilter.val() === '') {
          const currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth();

          if (currentMonth === 0) {
            // January
            datePublishedStartFilter.datepicker('setDate', new Date(currentYear - 1, 11));
          } else {
            datePublishedStartFilter.datepicker('setDate', new Date(currentYear, 0, 1));
          }
        }
      }

      const formSubmitHandler = (e) => {
        e.preventDefault();
        const data = this.getFormData(form, false);
        const beforeSubmitEvent = new CustomEvent('hx:beforeSubmit', {
          detail: data,
          cancelable: true
        });
        form[0].dispatchEvent(beforeSubmitEvent);
        if (beforeSubmitEvent.defaultPrevented) {
          return;
        }

        const params = this.getFilterParams(data);
        const newUrl = location.protocol + '//' + location.host + location.pathname + params;
        if (!formGroup.isReact && formGroup.isReact !== true) {
          history.replaceState({}, '', newUrl);
        }

        window.$.post('/api/filters/filter-group', {
          ...formGroup,
          data
        });

        const submitEvent = new CustomEvent('hx:submit', { detail: data });
        form[0].dispatchEvent(submitEvent);
      };
      form.on('submit', formSubmitHandler);
      this.events.push({ handler: formSubmitHandler, target: form, type: 'submit' });

      const formResetHandler = (e) => {
        e.preventDefault();

        form
          .find('input')
          .not(':button, :submit, :reset, :hidden, :radio')
          .val('')
          .prop('checked', false)
          .prop('selected', false);

        //remove checked from all radio buttons
        form.find('input:radio').each(function () {
          if (window.$(this).val() == 'all') {
            window.$(this).trigger('click').parent().addClass('active');
          } else {
            window.$(this).trigger('click').parent().removeClass('active');
          }
        });

        form.find('select').each(function () {
          window.$(this).val(null).trigger('change');
          if (window.$(this).data('filter-name') === 'SearchOptionsFilter') {
            window.$(this).val(window.$(this).find('option[selected]').val()).trigger('change');
          } else {
            window.$(this).find('option').remove();
          }
        });
      };
      form.on('reset', formResetHandler);
      this.events.push({ handler: formResetHandler, target: form, type: 'reset' });
    });

    window.$(window.forms).each((_, formId) => {
      const form = window.$(formId);

      const formSubmitHandler = (e) => {
        e.preventDefault();
        const beforeSubmitEvent = new CustomEvent('hx:beforeSubmit', { cancelable: true });
        form[0].dispatchEvent(beforeSubmitEvent);
        if (beforeSubmitEvent.defaultPrevented) {
          return;
        }

        const data = this.getFormData(form);

        const params = this.getFilterParams(data);
        const newUrl = location.protocol + '//' + location.host + location.pathname + params;
        history.replaceState({}, '', newUrl);

        this.updateLocalStorage(data);
        const submitEvent = new CustomEvent('hx:submit', { detail: data });
        form[0].dispatchEvent(submitEvent);
      };
      form.on('submit', formSubmitHandler);
      this.events.push({ handler: formSubmitHandler, target: form, type: 'submit' });
    });
  },
  getFilterParams(data) {
    if (!Object.entries(data).length) {
      return '';
    }

    return '?' + window.$.param(data);
  },
  getFormData(form, separate = false) {
    form = window.$(form);
    const data = window.$.deparam(location.search.slice(1));

    form.find('input:not([type=search])').each((_, input) => {
      const el = window.$(input);
      const name = el.attr('name');
      let value = el.val();
      if (el.is(':disabled')) {
        if (data[name]) {
          delete data[name];
        }
        return;
      }
      if (['checkbox', 'radio'].includes(el.attr('type'))) {
        value = input.checked ? value : null;
      }
      if (value?.trim()) {
        if (name.includes('[]')) {
          if (!data[name]) {
            data[name] = [];
          }
          data[name].push(value);
        } else {
          data[name] = value;
        }
      } else if (Object.keys(data).includes(name)) {
        if (el.attr('type') != 'radio' && el.attr('type') != 'checkbox') {
          delete data[name];
        }
      }
    });

    form.find('select').each((_, select) => {
      const el = window.$(select);
      const name = el.attr('name').replace('[]', '');

      if (el.is(':disabled')) {
        if (data[name]) {
          delete data[name];
        }
        return;
      }

      const selected = el.find('option:selected');
      const isMultiple = select.multiple ?? false;
      if (isMultiple) {
        data[name] = [];
      }
      selected.each((_, option) => {
        let value = window.$(option).val().trim();
        let text = window.$(option).text().trim();

        if (value?.trim()) {
          if (value === text) {
            if (isMultiple) {
              data[name].push(value);
            } else {
              data[name] = value;
            }
          } else {
            if (isMultiple) {
              data[name].push(value + (separate ? this.separator + text : ''));
            } else {
              data[name] = value + (separate ? this.separator + text : '');
            }
          }
        }
      });

      if (!selected.length && Object.keys(data).includes(name)) {
        delete data[name];
      }
    });

    Object.keys(data).forEach((key) => {
      if (key.includes('page')) {
        delete data[key];
      }
    });

    return data;
  },
  disableOptionsCodependantFilters() {
    const filters = {
      industry: window.$('[data-filter-name="IndustryFilter"]'),
      domain: window.$('[data-filter-name="DomainFilter"]'),
      topic: window.$('[data-filter-name="TopicFilter"]'),
      authority: window.$('[data-filter-name="AuthorityFilter"]'),
      specificAuthority: window.$('[data-filter-name="SpecificAuthorityFilter"]'),
      contractorGroup: window.$('[data-filter-name="ContractorGroupFilter"]'),
      contractor: window.$('[data-filter-name="ContractorFilter"]')
    };

    const getFilterName = (filter) =>
      filter
        .attr('data-filter-name')
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/Filter$/, '')
        .split(' ')
        .join(' ');

    const visuallyToggleFilter = (filter, enable, currentFilter) => {
      if (filter.length === 0 || currentFilter.length === 0) return;
      if (enable) {
        filter
          .next()
          .removeClass('visually-disabled')
          .attr('data-original-title', '')
          .tooltip('dispose');
      } else {
        const message = 'Results will be filtered by ' + getFilterName(currentFilter);
        filter.next().addClass('visually-disabled').attr('data-original-title', message).tooltip();
      }
    };

    const updateFiltersState = () => {
      const isTopicSelected = filters.topic.length > 0 && (filters.topic.val() || []).length !== 0;
      const isDomainSelected =
        filters.domain.length > 0 && (filters.domain.val() || []).length !== 0;
      const isSpecificAuthoritySelected =
        filters.specificAuthority.length > 0 &&
        (filters.specificAuthority.val() || []).length !== 0;
      const isContractorSelected =
        filters.contractor.length > 0 && (filters.contractor.val() || []).length !== 0;

      visuallyToggleFilter(
        filters.industry,
        !(isTopicSelected || isDomainSelected),
        isTopicSelected ? filters.topic : filters.domain
      );
      visuallyToggleFilter(filters.domain, !isTopicSelected, filters.topic);
      visuallyToggleFilter(
        filters.authority,
        !isSpecificAuthoritySelected,
        filters.specificAuthority
      );
      visuallyToggleFilter(filters.contractorGroup, !isContractorSelected, filters.contractor);
    };

    Object.values(filters).forEach((filter) => {
      if (filter.length > 0) {
        filter.on('change', updateFiltersState);
      }
    });

    updateFiltersState();
  },
  loadCodependantFilters() {
    window.$('[data-filter-name="SpecificAuthorityFilter"]').each((_, el) => {
      const select = window.$(el);
      const form = select.closest('form');

      const dependant = form.find('[data-filter-name="AuthorityFilter"]');

      if (dependant.length) {
        let oldDependantValue = dependant.val();

        const filterValue = {
          filterBy: {
            [dependant.attr('data-filter-id')]: dependant.val()
          }
        };

        const ajaxUrl = new URL(select.data('ajax'));
        select.attr(
          'data-ajax',
          ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
        );
        select.trigger('refresh');

        const dependantChangeHandler = () => {
          const filterValue = {
            filterBy: {
              [dependant.attr('data-filter-id')]: dependant.val()
            }
          };

          select.attr(
            'data-ajax',
            ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
          );
          select.trigger('refresh');

          let shouldReset;
          if (this.multiple) {
            shouldReset =
              oldDependantValue !== '' &&
              (dependant.val().length < oldDependantValue.length || oldDependantValue.length === 0);
          } else {
            shouldReset = oldDependantValue !== '' && dependant.val() !== oldDependantValue;
          }

          if (shouldReset) {
            select.find('option').each(function () {
              window.$(this).remove();
            });
            select.val(null).trigger('change');
          }

          oldDependantValue = dependant.val();
        };
        dependant.on('change', dependantChangeHandler);
        this.events.push({ handler: dependantChangeHandler, target: dependant, type: 'change' });
      }
    });
    window.$('[data-filter-name="ContractorFilter"]').each((_, el) => {
      const select = window.$(el);
      const form = select.closest('form');

      const dependant = form.find('[data-filter-name="ContractorGroupFilter"]');

      if (dependant.length) {
        let oldDependantValue = dependant.val();

        let filterValue = {
          filterBy: {
            [dependant.attr('data-filter-id')]: dependant.val()
          }
        };

        const ajaxUrl = new URL(select.data('ajax'));
        select.attr(
          'data-ajax',
          ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
        );
        select.trigger('refresh');

        const dependantChangeHandler = function () {
          filterValue = {
            filterBy: {
              [dependant.attr('data-filter-id')]: dependant.val()
            }
          };

          select.attr(
            'data-ajax',
            ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
          );
          select.trigger('refresh');

          let shouldReset;
          if (this.multiple) {
            shouldReset =
              oldDependantValue !== '' &&
              (dependant.val().length < oldDependantValue.length || oldDependantValue.length === 0);
          } else {
            shouldReset = oldDependantValue !== '' && dependant.val() !== oldDependantValue;
          }

          if (shouldReset) {
            select.find('option').each(function () {
              window.$(this).remove();
            });
            select.val(null).trigger('change');
          }

          oldDependantValue = dependant.val();
        };
        dependant.on('change', dependantChangeHandler);
        this.events.push({ handler: dependantChangeHandler, target: dependant, type: 'change' });
      }
    });

    window.$('[data-filter-name="DomainFilter"]').each((_, el) => {
      const select = window.$(el);
      const form = select.closest('form');

      const dependant = form.find('[data-filter-name="IndustryFilter"]');

      if (dependant.length) {
        let oldDependantValue = dependant.val();

        let filterValue = {
          filterBy: {
            [dependant.attr('data-filter-id')]: dependant.val()
          }
        };

        const ajaxUrl = new URL(select.data('ajax'));
        select.attr(
          'data-ajax',
          ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
        );
        select.trigger('refresh');

        const dependantChangeHandler = () => {
          filterValue = {
            filterBy: {
              [dependant.attr('data-filter-id')]: dependant.val()
            }
          };

          select.attr(
            'data-ajax',
            ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
          );
          select.trigger('refresh');

          let shouldReset;
          if (this.multiple) {
            shouldReset =
              dependant.val().length < oldDependantValue.length || oldDependantValue.length === 0;
          } else {
            shouldReset = dependant.val() !== oldDependantValue;
          }

          if (shouldReset) {
            select.find('option').each(function () {
              window.$(this).remove();
            });
            select.val(null).trigger('change');
          }

          oldDependantValue = dependant.val();
        };
        dependant.on('change', dependantChangeHandler);
        this.events.push({ handler: dependantChangeHandler, target: dependant, type: 'change' });
      }
    });

    window.$('[data-filter-name="TopicFilter"]').each((_, el) => {
      const select = window.$(el);
      const form = select.closest('form');

      let industrySelect = form.find('[data-filter-name="IndustryFilter"]');
      let divisionSelect = form.find('[data-filter-name="DomainFilter"]');

      if (divisionSelect.length && industrySelect.length) {
        let oldDivisionValue = divisionSelect.val();
        let oldIndustryValue = industrySelect.val();

        let filterValue = {
          filterBy: {
            [industrySelect.attr('data-filter-id')]: oldIndustryValue,
            [divisionSelect.attr('data-filter-id')]: oldDivisionValue
          }
        };

        const ajaxUrl = new URL(select.data('ajax'));
        select.attr(
          'data-ajax',
          ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
        );
        select.trigger('refresh');

        const divisionSelectChangeHandler = () => {
          filterValue = {
            filterBy: {
              [industrySelect.attr('data-filter-id')]: industrySelect.val(),
              [divisionSelect.attr('data-filter-id')]: divisionSelect.val()
            }
          };

          select.attr(
            'data-ajax',
            ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
          );
          select.trigger('refresh');

          let shouldReset;
          if (this.multiple) {
            shouldReset =
              divisionSelect.val().length < oldDivisionValue.length ||
              oldDivisionValue.length === 0;
          } else {
            shouldReset = divisionSelect.val() !== oldDivisionValue;
          }

          if (shouldReset) {
            select.find('option').each(function () {
              window.$(this).remove();
            });
            select.val(null).trigger('change');
          }

          oldDivisionValue = divisionSelect.val();
        };
        divisionSelect.on('change', divisionSelectChangeHandler);
        this.events.push({
          handler: divisionSelectChangeHandler,
          target: divisionSelect,
          type: 'change'
        });

        const industrySelectChangeHandler = () => {
          filterValue = {
            filterBy: {
              [industrySelect.attr('data-filter-id')]: industrySelect.val(),
              [divisionSelect.attr('data-filter-id')]: divisionSelect.val()
            }
          };

          const ajaxUrl = new URL(select.data('ajax'));
          select.attr(
            'data-ajax',
            ajaxUrl.href + (ajaxUrl.search ? '&' : '?') + window.$.param(filterValue)
          );
          select.trigger('refresh');

          let shouldReset;
          if (this.multiple) {
            shouldReset =
              industrySelect.val().length < oldIndustryValue.length ||
              oldIndustryValue.length === 0;
          } else {
            shouldReset = industrySelect.val() !== oldIndustryValue;
          }

          if (shouldReset) {
            select.find('option').each(function () {
              window.$(this).remove();
            });
            select.val(null).trigger('change');
          }

          oldIndustryValue = industrySelect.val();
        };
        industrySelect.on('change', industrySelectChangeHandler);
        this.events.push({
          handler: industrySelectChangeHandler,
          target: industrySelect,
          type: 'change'
        });
      }
    });
  },
  loadFormAjaxFilters(identifier) {
    window
      .$(identifier)
      .find('select[data-ajax]')
      .each((_, el) => {
        const route = new URL(el.dataset.ajax);

        // Add selected ids to route
        Array.from(el.options)
          .filter(
            (option) =>
              option.selected && !['starts', 'contains'].includes(option.value.split('_')[0])
          )
          .forEach((option) => {
            route.searchParams.append('selected[]', option.value);
          });

        if (route.searchParams.has('selected[]')) {
          window.$.ajax({
            url: route.href,
            method: 'GET',
            success: (data) => {
              el.querySelectorAll('option').forEach((item) => {
                window.testResult = data.results;
                const newItem = data.results.find((result) => result.id == item.value);
                if (newItem) {
                  el.append(new Option(newItem.text, newItem.id, true, true));
                }
                item.remove();
              });
            }
          });
        }
      });
  },
  loadAjaxFilters() {
    if (!window.formGroups || !window.formGroups.length) return;
    window.formGroups.forEach((formGroup) => {
      this.loadFormAjaxFilters('#' + formGroup.identifier);
    });
  },
  init() {
    this.initForms();
  }
};

document.addEventListener('DOMContentLoaded', () => {
  if (window.hxForms) return;
  window.hxForms = hxForms;

  hxForms.init();
});

export default hxForms;
